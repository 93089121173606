<template>
  <el-dialog :title="title"
             :visible.sync="visibleValue"
             :fullscreen="fullscreen"
             :modal="modal"
             :modal-append-to-body="modalAppendToBody"
             :append-to-body="appendToBody"
             :custom-class="customClass"
             :close-on-click-modal="closeOnClickModal"
             :close-on-press-escape="closeOnPressEscape"
             :show-close="showClose"
             :before-close="beforeClose"
             :destroy-on-close="destroyOnClose"
             :width="width"
             @closed="closed">
    <div class="wma-pop flex flex-column align-items-center justify-content-center">
      <el-image style="width: 200px; height: 200px" :src="goodsWxaCodeUrl" fit="cover"></el-image>
      <!--产品说这里的图太单一了，让泽华画个好看的-->
      <el-button type="success" plain @click="downShareGoodsCode()">下载</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {templateElDialog} from '../../public/template/template-el-dialog'
  import downImage from '../../../assets/js/downImage';
  export default {
    name: 'share-goods-dialog',
    mixins:[templateElDialog],
    props:{
      title:{
        type:String,
        default:'分享商品'
      },
      width: { //	Dialog 的宽度
        type: String,
        default: '450px',
      },
      goodsWxaCodeUrl:{
        type: String,
        default: '',
      }
    },
    methods:{
      downShareGoodsCode(){
        if(this.goodsWxaCodeUrl){
          let url = this.goodsWxaCodeUrl;
          downImage(url,'商品分享码','jpg',false);
        }
      },
    }
  }
</script>

<style scoped>

</style>
