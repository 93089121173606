<template>
  <el-dialog :title="title"
             :visible.sync="visibleValue"
             :fullscreen="fullscreen"
             :modal="modal"
             :modal-append-to-body="modalAppendToBody"
             :append-to-body="appendToBody"
             :custom-class="customClass"
             :close-on-click-modal="closeOnClickModal"
             :close-on-press-escape="closeOnPressEscape"
             :show-close="showClose"
             :before-close="beforeClose"
             :destroy-on-close="destroyOnClose"
             :width="width"
             @closed="closed">
    <div class="draw-canvas-qr draw-store-qr">
      <div class="draw-qr-box" v-loading="!base64">
        <canvas :id="canvasId"></canvas>
      </div>
      <div class="hint-list" v-if="showHint">
        <div class="hint-text" :class="[isErrorText(item)]" v-for="(item,index) in hintArray">【{{index}}】{{item}}</div>
      </div>
      <div class="action">
        <el-button type="primary"
                   @click="downImage"
                   :disabled="!base64">下载图片</el-button>
      </div>
      <div class="hint-one">
        <div class="hint-text" :class="[isErrorText(hintArray[hintArray.length - 1])]" v-if="hintArray.length">【{{hintArray.length - 1}}】{{hintArray[hintArray.length - 1]}}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import {canvasQrBase} from "../../public/template/canvasQr/canvasQrBase";
  import {URL,PATH_LOGO_STORE,PROJECT_NAME} from "../../../config";
  import {drawBeforeImages} from "../../../assets/js/downImage";
  import {PATH_IMAGES} from "../../../config";
  import {isEmpty} from "../../../assets/js/utils";

  let defaultBrief = "发现一个好物，推荐给你呀";
  function returnBaseUserInfo(){
    return {
      userLogo: PATH_LOGO_STORE,
      userName: "用户未登录",
      inviteCode: defaultBrief,
    }
  }
  function returnBaseGoodsInfo(){
    return {
      goodsName: '商品为空',
      goodsPrice: "价格错误",
      goodsMarketPrice: "",
      goodsCover:PATH_IMAGES + '/noData/no_data_shopping.png',
    }
  }
  export default {
    name: "canvasQrGoods",
    mixins:[canvasQrBase],
    props:{
      title: {
        type: String,
        default: '商品推广码'
      },
      userInfo:{
        type: Object,
        default:function(){
          return returnBaseUserInfo();
        }
      },
      goodsInfo:{
        type: Object,
        // default:function(){
        //   return returnBaseUserInfo();
        // }
      }
    },
    data(){
      return {
        /* 是否在显示时绘制 */
        showStartDraw:false,
        /* 处理后的商品数据 */
        goodsData:{},
      }
    },
    created(){
      if(!isEmpty(this.goodsInfo)){
        this.goodsData = this.disposeGoodsInfo(this.goodsInfo);
      }
    },
    mounted() {},
    methods:{
      disposeGoodsInfo(goods){
        let base = returnBaseGoodsInfo();
        if(!(typeof goods === 'object' && goods !== null)) return base;
        return {
          goodsName:goods.name || goods.goodsName || base.goodsName,
          goodsPrice:goods.price || goods.goodsPrice || base.goodsPrice,
          goodsMarketPrice:goods.marketPrice || goods.goodsMarketPrice || base.goodsMarketPrice,
          goodsCover:goods.cover || goods.goodsCover || base.goodsCover,
        };
      },
      /**
       * 判断绘制条件
       */
      judgeDrawPrecondition(){
        this.pushHint('判断绘制前提条件');
        if(!this.$store.state.token){
          return this.pushHint('没有登录 error');
        }
        if(typeof this.userInfo != 'object'){
          return this.pushHint('没有店铺数据 error');
        }
        if(!this.userInfo.userName){
          return this.pushHint('没有店铺名称 error');
        }
        if(!this.userInfo.userLogo){
          return this.pushHint('没有店铺头像 error');
        }
        if(typeof this.goodsData != 'object'){
          return this.pushHint('没有商品数据 error');
        }
        if(!this.goodsData.goodsName){
          return this.pushHint('没有商品名称 error');
        }
        if(!this.goodsData.goodsCover){
          return this.pushHint('没有商品宝贝图 error');
        }
        if(!this.goodsData.goodsPrice){
          return this.pushHint('没有商品价格 error');
        }
        this.getWxQr().then(res=>{
          return this.drawBeforeImagesQr(res);
        }).then(res=>{
          return this.drawBeforeImagesUser();
        }).then(res=>{
          return this.drawBeforeImagesGoods();
        }).then(res=>{
          this.$nextTick(()=>{
            this.createCanvas();
          });
        });
      },
      /**
       * 获取店铺小程序码
       */
      getWxQr(){
        this.pushHint('获取 小程序码');
        return this.axios.get(URL.supplierGoods.getWxaCode, {
          timeout:6e3,
          params:{
            goodsId:this.goodsInfo.id,
          },
        }).then(res=>{
          if(res.code === 0 && res.data.url) {
            return Promise.resolve(res.data.url);
          }else{
            return Promise.reject(res);
          }
        }).catch(res=>{
          this.pushHint('获取 小程序码 error ' + res.message||'');
          return Promise.reject(res);
        })
      },
      /**
       * 下载图片
       */
      drawBeforeImagesGoods(src = this.goodsData.goodsCover){
        this.pushHint('下载 商品主图 本地资源');
        src = this.ossImagesMFit(src,this.size.width);
        return drawBeforeImages(src).catch(res=>{
          this.pushHint('下载 商品主图 error ' + res.message||'');
        }).then(res=>{
          this.filePaths.goods = res;
          return Promise.resolve();
        });
      },
      drawBeforeImagesUser(src = this.userInfo.userLogo){
        this.pushHint('下载 用户头像 本地资源');
        if(!src){
          let defaultUser = returnBaseUserInfo();
          src = defaultUser.userLogo;
        }
        src = this.ossImagesMFit(src,100);
        return drawBeforeImages(src).catch(res=>{
          this.pushHint('下载 用户头像 error ' + res.message||'');
        }).then(res=>{
          this.filePaths.user = res;
          return Promise.resolve();
        });
      },
      drawBeforeImagesQr(src){
        this.pushHint('下载 小程序码 本地资源',src);
        src = this.ossImagesMFit(src,120);
        return drawBeforeImages(src).catch(res=>{
          this.pushHint('下载 小程序码 error ' + res.message||'');
        }).then(res=>{
          this.filePaths.qr = res;
          return Promise.resolve();
        });
      },
      /**
       * 绘制过程
       * @param ctx
       */
      canvasDrawStart(ctx){
        this.pushHint('绘制 开始');
        this.drawBg(ctx);
        this.drawGoodsCover(ctx);
        this.drawGoodsPrice(ctx);
        this.drawGoodsText(ctx);
        this.drawUser(ctx);
        this.drawQr(ctx);
        this.pushHint('绘制 success');
      },
      drawGoodsCover(ctx){
        this.pushHint('绘制 商品主图');
        if(this.filePaths.goods) ctx.drawImage(this.filePaths.goods, 0, 0, this.size.width, this.size.width);
        ctx.restore();
      },
      drawGoodsPrice(ctx){
        this.pushHint('绘制 商品价格');
        let goods = this.goodsData;
        let goodsPrice = goods.goodsPrice.toFixed(2);
        let goodsMarketPrice = goods.goodsMarketPrice ? '￥' + goods.goodsMarketPrice.toFixed(2) :'';
        let goodsPriceFontSize = 56;
        let goodsMarketPriceFontSize = 36;

        ctx.font = `${goodsMarketPriceFontSize}px PingFangSC-Semibold, PingFang SC`;
        ctx.fillStyle = '#FF4F4F';
        let x = this.size.paddingLeft;
        let y = 675;
        ctx.fillText('￥',x,y);

        x += ctx.measureText('￥').width;
        ctx.font = `bold ${goodsPriceFontSize}px PingFangSC-Semibold, PingFang SC`;
        ctx.fillText(goodsPrice,x,y);
        if(goodsMarketPrice){
          x += ctx.measureText(goodsPrice).width;
          x += 20;
          ctx.font = `${goodsMarketPriceFontSize}px PingFangSC-Semibold, PingFang SC`;
          ctx.fillStyle = '#999999';
          ctx.fillText(goodsMarketPrice,x,y);

          y = parseInt( y - goodsMarketPriceFontSize * 0.4);
          if(!1){
            ctx.fillRect(x,y, ctx.measureText(goodsMarketPrice).width, 3);
          }else{
            ctx.strokeStyle = '#999999';
            ctx.beginPath();
            ctx.lineWidth = 3;
            ctx.moveTo(x,y);
            x += ctx.measureText(goodsMarketPrice).width;
            ctx.lineTo(x , y);
            ctx.stroke();
          }
        }

        ctx.restore();
      },
      drawGoodsText(ctx){
        this.pushHint('绘制 商品标题');
        let x = this.size.paddingLeft;
        let y = 705;
        let goodsTitleFontSize = 28;
        ctx.font = `${goodsTitleFontSize}px PingFangSC-Semibold, PingFang SC`;
        ctx.fillStyle = '#333333';
        let nameArray = this.splitArrayFromTextMaxWidth(ctx,this.goodsData.goodsName,400);
        for( let i in nameArray){
          if(i>=2) break;
          y += goodsTitleFontSize;
          ctx.fillText(nameArray[i],x,y);
          y += 10;
        }
        ctx.restore();
      },
      splitArrayFromTextMaxWidth(ctx,name,maxWidth){
        if(!name) return [];
        if(!maxWidth) return [name];
        let arr = [];
        let strWidth = ctx.measureText(name).width;
        console.log('strWidth',strWidth,name);
        if(strWidth > maxWidth){
          let len = parseInt(name.length * maxWidth/strWidth);
          let str = name.slice(0,len);
          if(ctx.measureText(str).width > maxWidth){
            str = this.splitArrayFromTextMaxWidth(ctx,str,maxWidth)[0]||'';
          }
          arr.push(str);
          str = name.slice(str.length );
          arr.push(...this.splitArrayFromTextMaxWidth(ctx,str,maxWidth));
        }else{
          arr.push(name);
        }
        return arr;
      },
      drawQr(ctx){
        this.pushHint('绘制 小程序码');
        let qrWidth = 116;
        let x = this.size.width - qrWidth  - this.size.paddingLeft;
        let y = 745;
        if(this.filePaths.qr) ctx.drawImage(this.filePaths.qr, x, y, qrWidth, qrWidth);
        y += qrWidth;
        y += 8;
        ctx.font = '24px PingFangSC-Semibold, PingFang SC';
        ctx.fillStyle = '#666666';
        y += 24;
        let hint = '扫码查看商品';
        ctx.textAlign = 'center';
        let hintWidth = ctx.measureText(hint).width;
        ctx.fillText(hint,x + hintWidth/2 - (hintWidth - qrWidth)/2  ,y);
        ctx.textAlign = 'left';
        ctx.restore();
      },
      drawUser(ctx){
        this.pushHint('绘制 店铺头像/名称/简介');
        let x = this.size.paddingLeft;
        let y = 800;
        let portraitWidth = 80;
        let user = this.filePaths.user;
        let nickName = this.userInfo.userName;
        let inviteCode = this.userInfo.inviteCode||defaultBrief;
        ctx.save();
        ctx.beginPath();
        ctx.arc(x +  portraitWidth/ 2 , y + portraitWidth / 2, portraitWidth / 2, 0, Math.PI * 2, false);
        ctx.clip();
        if(user) {
          ctx.drawImage(user, x, y, portraitWidth, portraitWidth);
        }
        ctx.restore();
        //名称
        x += portraitWidth;
        x += 20;
        y += 28;
        ctx.font = 'bold 28px PingFangSC-Semibold, PingFang SC';
        ctx.fillStyle = '#333333';
        ctx.fillText(nickName,x ,y);
        // 邀请码
        y += 20;
        y += 24;
        ctx.font = '24px PingFangSC-Semibold, PingFang SC';
        ctx.fillStyle ='#FF4F4F';
        ctx.fillText(inviteCode,x ,y);
        x = 0;
        y = 0;
        ctx.restore();
      },

      /**
       * 下载图片生成的名字
       * @returns {string}
       */
      setDownImageName(){
        return this.goodsData.goodsName;
      },
    },
    computed:{},
    watch:{
      goodsInfo(newValue){
        this.goodsData = this.disposeGoodsInfo(newValue);
      },
      goodsData(newValue){
        if(!newValue) return false;
        this.pushHint('更新 新的商品数据',JSON.stringify(newValue));
        this.clearDrawParams('goodsData');
        setTimeout(()=>{
          this.judgeDrawPrecondition();
        },100);
      },
    },
  }
</script>


<style scoped lang="less">
  @import "../../public/template/canvasQr/canvasQrBase";
</style>
