<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages scroll-none" v-loading="loading">

    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select v-model="searchData.searchType" placeholder="请选择（上下架）" style="width: 120px">
              <el-option v-for="item in searchKeyType" :disabled="item.disabled" :key="item.id" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input class="" placeholder="搜索关键字" prefix-icon="el-icon-search" v-model="searchData.searchValue"
              @keyup.enter.native="search" style="width: 260px">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-select v-model="searchData.status" placeholder="上架状态" style="width: 120px">
              <el-option v-for="item in saleStatusArray" :disabled="item.disabled" :key="item.id" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="isSupportWholesale == 1">
            <el-select v-model="searchData.businessType" placeholder="商品类型" style="width: 120px">
              <el-option v-for="item in goodsTypeArr" :disabled="item.disabled" :key="item.id" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否是预告商品">
            <el-select v-model="searchData.isPresell" placeholder="预告状态" style="width: 120px">
              <el-option v-for="(item, key) in recommendStatus" :key="key" :label="item" :value="parseInt(key)">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-cascader class="width-all"
                         ref="region"
                         v-model="classifyValue"
                         :options="[{id:'',name:'全部分类'}].concat(classifyList)"
                         :props="{checkStrictly: true,value:'id',label:'name'}"></el-cascader>
          </el-form-item> -->

          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="reset">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="downloadExcel" icon="el-icon-download">导出</el-button>
          </el-form-item>
        </el-form>
        <div class="margin-bottom">
          <el-button type="primary" @click="batchOnShelf">批量上架</el-button>
          <el-button type="danger" @click="batchOffShelf">批量下架</el-button>
        </div>
      </template>
      <template v-slot:table="row">
        <el-table @selection-change="handleSelectionChange" :data="tableData" :height="row.heightText"
          v-if="row.height">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="id" label="ID" width="180">
          </el-table-column>
          <el-table-column label="封面图" width="100">
            <template slot-scope="scope">
              <img class="goods-cover" :src="scope.row.cover">
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称" width="160">
          </el-table-column>
          <el-table-column label="商品类型" width="100">
            <template slot-scope="scope">
              {{ businessTypearr[scope.row.businessType] }}
            </template>
          </el-table-column>
          <el-table-column prop="cateDetails" label="分类" width="180">
          </el-table-column>
          <el-table-column prop="brandName" label="品牌" width="100">
          </el-table-column>
          <el-table-column label="状态" width="100">
            <template slot-scope="scope">
              {{ saleStatus[scope.row.status] }}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="售价" width="80">
          </el-table-column>
          <el-table-column label="是否支持赠送" width="120">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isSupportGift === 1"
                @change="isFreeGift(scope.row.id, scope.row.isSupportGift)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="purchasePrice" label="供货价 " width="80" v-if="showPoint">
          </el-table-column>
          <el-table-column prop="cost" label="扣点金额" width="100" v-if="showPoint">
          </el-table-column>
          <el-table-column prop="onShelfTime_text" label="上架时间" width="100">
          </el-table-column>
          <el-table-column label="云集市的热推款" width="120">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isRecommend === 1"
                @change="toggleRecommend(scope.row.id, scope.row.isRecommend)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime_text" label="更新时间" width="100">
          </el-table-column>
          <el-table-column label="是否置顶" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isTop === 1" :disabled="scope.row.status == 2"
                @change="isRowTop(scope.row.id, scope.row.isTop)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="topTime_text" label="置顶时间" width="100">
            <template slot-scope="scope">
              {{ scope.row.isTop === 1 ? scope.row.topTime_text : '- -' }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="300">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-view" @click="editGoods(scope.row)">详情</el-button>
              <!-- <el-button type="primary" size="mini" icon="el-icon-share"  @click="showShareGoods(scope.row)" v-if="$store.state.userInfo">分享</el-button> -->
              <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteOfficialGoods(scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template slot="after">
        <div class="paging-row">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 15, 20]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="totalListNum">
          </el-pagination>
        </div>
      </template>
    </table-height-auto>

    <canvas-qr-goods :visible.sync="visibleDialogQrGoods" :user-info="userInfo" :goods-info="shareGoodsData">
    </canvas-qr-goods>
  </div>
</template>

<script>
import { URL } from '../../../config'
import { disposeSearchData, JSONStringify, parseTimeArray, deepsClone } from '../../../assets/js/utils'
import { disposeTreeData } from '../../../assets/js/goodsClassify'
import {
  goodsReviewStatusArray,
  isDeleteStatus,
  isDeleteStatusArray,
  saleStatus,
  saleStatusArray,
  goodsTypeArr,
  recommendStatus,
} from '../../../assets/js/status'
import { deleteOfficialGoods } from '../../../assets/js/supplier/goods'
import { plusDownloadExcelTask } from '../../../store/excel'
import ShareGoodsDialog from "../../../components/supplier/goods/shareGoodsDialog";
import canvasQrGoods from "../../../components/supplier/canvasQr/canvasQrGoods";
import { mapState } from 'vuex'

const searchData = {
  isDelete: 0,
  isPresell: 0,
  status: 0,
  searchType: 'goodsName',
  searchValue: '',
  businessType: '',
  firstCateId: '',// 一级分类ID
  secCateId: '',// 2级分类ID
  thirdCateId: '',// 3级分类ID
}

export default {
  name: 'supplierGoodsList',
  components: {
    ShareGoodsDialog,
    canvasQrGoods,
  },
  data() {
    return {
      loading: false,
      /* 数据表格 */
      saleStatus,
      saleStatusArray,
      isDeleteStatus,
      isDeleteStatusArray,
      recommendStatus,
      goodsReviewStatusArray: goodsReviewStatusArray.filter(res => [1].includes(res.id)),
      searchKeyType: [
        { id: 'goodsId', name: '商品编码' },
        { id: 'goodsName', name: '商品名称' },
        { id: 'brandName', name: '品牌名称' },
        { id: 'keywords', name: '商品关键词' },

      ],
      goodsTypeArr: [{
        id: '',
        name: '全部类型'
      }, {
        id: '1',
        name: '普通商品'
      }, {
        id: '2',
        name: '包装商品'
      }, {
        id: '3',
        name: '代运营'
      }, {
        id: '4',
        name: '产品拍图'
      }],
      isSupportWholesale: JSON.parse(localStorage.getItem('user_info')).isOpenB2b, // 只有供应商才能上传批发商品
      classifyList: [],
      classifyValue: [''],
      searchData: deepsClone(searchData),
      tableData: [], // 数据列表
      currentPage: 1, // 当前页码
      totalListNum: 0, // 列表总条量
      pageSize: 10, // 每页显示得条数
      multipleSelection: [],
      /* 新增属性组弹窗 */
      visibleAddNew: false,
      goodsWxaCodeUrl: '',//商品的小程序码链接
      /* 绘制推广码 */
      visibleDialogQrGoods: false,
      shareGoodsData: {},
      businessTypearr: ['', '零售商品', '包装', '运营商品', '产品拍图']
    }
  },
  created() {
    this.parseQuery();
    this.getListClassify();
    this.getList();
  },
  methods: {
    parseQuery() {
      let params = this.$route.query;
      if (params['status'] === '1') {
        this.searchData.status = 1
      }
      if (params.currentPage) {
        this.currentPage = parseInt(params.currentPage) || 1;
      }
    },
    /**
     * 拼接搜索参数
     */
    jointSearchData() {
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      };
      data = Object.assign(data, this.searchData);
      disposeSearchData(data)
      return data;
    },
    /**
     * 搜索
     */
    search(type) {
      this.currentPage = 1;
      this.getList()
    },
    // 重置
    reset() {
      this.searchData = deepsClone(searchData);
      this.currentPage = 1;
      this.totalListNum = 0;
      this.pageSize = 10;
      this.getList();
    },
    /**
     * 查询列表数据
     */
    getList() {
      return new Promise((resolve, reject) => {
        let data = this.jointSearchData();
        this.loading = true;
        this.axios.get(URL.supplierGoods.list, { params: data }).then(res => {
          parseTimeArray(res.data.records, ["updateTime", "onShelfTime", "topTime"])
          this.totalListNum = parseInt(res.data.total);
          this.tableData = res.data.records;
          resolve(res);
        }).catch(res => {
          this.tableData = [];
          // reject(res);
        }).finally(res => {
          this.multipleSelection = []
          this.loading = false;
        })
      })
    },
    /**
     * pageSize 改变时会触发：选择每页显示条数
     */
    handleSizeChange(val, type) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList()
    },
    /**
     * currentPage 改变时会触发：选择页数
     */
    handleCurrentChange(val, type) {
      this.currentPage = val;
      this.getList();
    },
    /**
     * handleSelectionChange 当选择项发生变化时会触发该事件 table 表格选中
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection)
    },
    /**
     * 编辑商品
     */
    editGoods(data) {
      this.$router.push({
        path: '/supplierEditGoods',
        query: {
          isOfficial: 1,
          draftId: data.id,
          originPageUrL: `/supplierGoods?currentPage=${this.currentPage}`,
        }
      })
    },
    /**
     * 获取分类列表
     */
    getListClassify() {
      let data = {
        status: 1,
      };
      this.loading = true;
      this.axios.get(URL.supplierGoods.getSupplierBrandCateList, { params: data }).then(res => {
        this.classifyList = disposeTreeData(res.data);
      }).catch(res => {
        this.classifyList = []
        // return Promise.reject(res);
      }).finally(res => {
        this.loading = false;
      })
    },
    /**
     * 删除正式商品
     */
    deleteOfficialGoods: function (row) {
      deleteOfficialGoods.apply(this, [row.id]).then(() => {
        this.getList();
      })
    },
    batchOnShelf() {
      if (!this.multipleSelection.length) return this.$message.error('没有选中列表')
      let data = {
        ids: this.multipleSelection.map(res => res.id),
      }
      this.loading = true;
      this.axios.post(URL.supplierGoods.onShelf, data).then(res => {
        this.getList()
      }).catch(res => {
        // return Promise.reject(res);
      }).finally(res => {
        this.loading = false;
      })
    },
    batchOffShelf() {
      if (!this.multipleSelection.length) return this.$message.error('没有选中列表')
      let data = {
        ids: this.multipleSelection.map(res => res.id),
      }
      this.loading = true;
      this.axios.post(URL.supplierGoods.offShelf, data).then(res => {
        console.log(res);
        this.getList()
      }).catch(res => {
        // return Promise.reject(res);
      }).finally(res => {
        this.loading = false;
      })
    },
    isFreeGift(id, isSupportGift) {
      let data = {
        goodsId: id,
      };
      let tipsMsg = isSupportGift === 1 ? "你确定要取消赠送吗？" : "你确定要添加赠送吗？";
      let actionMsg = isSupportGift === 1 ? "取消赠送" : "添加赠送";
      this.$confirm(tipsMsg, actionMsg, {
        confirmButtonText: '确定',
        type: isSupportGift === 1 ? 'error' : 'success'
      }).then(() => {
        this.loading = true
        this.axios.post(URL.supplierGoods.isSupportGift, this.qs.stringify(data)).then(res => {
          if (res.code === 0) {
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(res => {
      }).finally(res => {
        this.loading = false
      })
    },
    toggleRecommend(id, isRecommend) {
      let data = {
        goodsId: id,
      };
      let tipsMsg = isRecommend === 1 ? "你确定要取消推荐吗？" : "你确定要添加推荐吗？";
      let actionMsg = isRecommend === 1 ? "取消推荐" : "添加推荐";
      this.$confirm(tipsMsg, actionMsg, {
        confirmButtonText: '确定',
        type: isRecommend === 1 ? 'error' : 'success'
      }).then(() => {
        this.loading = true
        this.axios.post(URL.supplierGoods.toggleRecommend, this.qs.stringify(data)).then(res => {
          if (res.code === 0) {
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(res => {
      }).finally(res => {
        this.loading = false
      })
    },
    //
    isRowTop(id, isTop) {
      let data = {
        goodsId: id,
      };
      let tipsMsg = isTop === 1 ? "你确定要取消置顶吗？" : "你确定要添加置顶吗？";
      let actionMsg = isTop === 1 ? "取消置顶" : "添加置顶";
      this.$confirm(tipsMsg, actionMsg, {
        confirmButtonText: '确定',
      }).then(() => {
        this.loading = true
        this.axios.get(URL.supplierGoods.doTop, { params: data }).then(res => {
          if (res.code === 0) {
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(res => {
      }).finally(res => {
        this.loading = false
      })
    },
    /**
     * 导出excel
     */
    downloadExcel() {
      let searchData = this.jointSearchData();
      let data = {
        name: '供应商商品报表',
        params: {
          excelType: 1003,
          excelParameterJson: JSON.stringify(searchData),
        },
      };
      plusDownloadExcelTask.call(this, data);
    },
    showShareGoods(item) {
      this.shareGoodsData = item;
      this.visibleDialogQrGoods = true;
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      showPoint: state => state.userInfo.showCostAndPurchase,
    }),
  },
  watch: {
    classifyValue: function (newValue) {
      let [firstCateId, secCateId, thirdCateId] = newValue
      this.searchData.firstCateId = firstCateId
      this.searchData.secCateId = secCateId
      this.searchData.thirdCateId = thirdCateId
      this.search()
    },
  }
}
</script>

<style lang="less">
.form-item {
  .el-input {
    max-width: 300px;
  }
}
</style>
<style lang="less" scoped>
.goods-cover {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
</style>
